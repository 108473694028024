.l-f {
  background-color: $gray;
  &__inner {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
    padding: 60px 0 20px;
    @include mq-max(content) {
      padding: 60px $contentPadding 20px;
    }
    @include mq-max(sm) {
      padding: 50px $contentPadding/2 20px;
    }
  }
  &__above {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 60px;
    @include mq-max(content) {
      flex-direction: column;
    }
  }
  &__bottom {
    width: 100%;
    .l-f {
      &__link {
        padding-left: 0;
        display: flex;
        justify-content: flex-end;
        @include mq-max(iPadPro-v) {
          flex-wrap: wrap;
          justify-content: flex-start;
        }
        &-item {
          margin-bottom: 0;
          margin-right: 36px;
          @include mq-max(iPadPro-v) {
            margin-bottom: 20px;
            margin-right: 0;
            padding-right: 8px;
            width: 50%;
          }
          &:last-child {
            margin-right: 0;
          }
          &>a {
            font-size: 14px;
            color: $textColor;
            &:before {
              top: .4em;
            }
          }
        }
      }
    }
  }

  //左側
  &__info {
    max-width: 330px;
    width: 100%;
    @include mq-max(content) {
      max-width: 100%;
      margin-bottom: 40px;
    }
  }
  &__logo {
    max-width: 206px;
    margin-bottom: 30px;
    &>img {
      width: 100%;
      display: block;
      line-height: 1;
    }
  }
  &__address {
    font-size: 16px;
    font-weight: 500;
    line-height: 2;
  }

  //右側
  &__links {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7.5px;
    @include mq-max(iPadPro-v) {
      margin-bottom: -45px;
    }
    &-wrap {
      max-width: calc(100% - 330px);
      width: 100%;
      @include mq-max(content) {
        max-width: 100%;
      }
    }
    &-block {
      width: 25%;
      padding: 0 7.5px;
      @include mq-max(iPadPro-v) {
        width: 50%;
        margin-bottom: 45px;
      }
    }
    &-head {
      font-size: 16px;
      font-weight: 600;
      border-bottom: 1px solid $black;
      padding-bottom: 7px;
      margin-bottom: 15px;
      @include mq-max(content) {
        font-size: 15px;
      }
      @include mq-max(iPadPro-v) {
        font-size: 16px;
      }
      &-ico {
        &:before {
          content: '';
          display: block;
          margin-bottom: 4px;
        }
        &--plan {
          &:before {
            width: 21px;
            height: 21px;
            background: url('/inc/image/common/ico/ico_wallet.svg')no-repeat center / contain;
          }
        }
        &--access {
          &:before {
            width: 16.5px;
            height: 21px;
            background: url('/inc/image/common/ico/ico_marker.svg')no-repeat center / contain;
          }
        }
        &--about {
          &:before {
            width: 21px;
            height: 21px;
            background: url('/inc/image/common/ico/ico_school.svg')no-repeat center / contain;
          }
        }
        &--guide {
          &:before {
            width: 28px;
            height: 21px;
            background: url('/inc/image/common/ico/ico_guide.svg')no-repeat center / contain;
          }
        }
      }
    }
  }
  &__link {
    padding-left: 4px;
    &-item {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      &>a {
        font-size: 16px;
        font-weight: 500;
        display: inline-block;
        padding-left: 20px;
        position: relative;
        color: $keyColor;
        &:before {
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 4.5px 0 4.5px 12px;
          border-color: transparent transparent transparent $keyColor-hover;
          position: absolute;
          top: .55em;
          left: 0;
          transition: all $transition;
        }
        &:hover {
          color: $keyColor-hover;
          &:before {
            left: 5px;
          }
        }
      }
    }
  }


  //Copyright
  &__copyright {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    display: block;
    color: $white;
    background-color: $keyColor;
    padding: 10px 5px;
  }
}
