//サイトレイアウト構造を記述

#container {}
#main {}
#contents {
  padding: 0;
  @include mq-max(sm) {
    width: $full-size;
    padding: 0;
  }
}

//gridlex調整
[class*="grid"] {
  margin-bottom: -1 * $contentPadding; //gridlexを使うことでできてしまう下余白を相殺。
}
[class*="col-"] {
  img {
    width: 100%;
    display: block;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  基本ブロックレイアウト

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-section {
  width: 100%;
  max-width: $contentWidth;
  margin: 0 auto;
  padding: $sectionPadding 0;
  @include mq-max(content) {
    padding: 0 $contentPadding $sectionPadding;
  }
  @include mq-max(sm) {
    padding: 0 $contentPadding/2 $sectionPadding;//お好みで変更
  }
  &__inner {
    padding: 0 0 $sectionInnerPadding;//お好みで変更
    &:last-child {
      padding-bottom: 0;
    }
  }

  //横幅調整用
  &--medium {
    max-width: 800px;
    margin: 0 auto;
    @include mq-max(xs) {
      max-width: 100%;
    }
  }
  &--small {
    max-width: 600px;
    margin: 0 auto;
    @include mq-max(xs) {
      max-width: 100%;
    }
  }

  //grid調整(デフォで付く下余白をマージンで相殺)
  // [class*="grid"] {
  //   margin-bottom: -1 * $contentPadding;
  // }
}

//background共通設定
@mixin bg_scss() {
  @include mq-max(content) {
    padding-top: $sectionPadding;
    margin: 0 0 $sectionPadding;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

//背景色（.l-sectionを囲って使う）
.l-bg {
  @include bg_scss();
  &--keyColor {
    background-color: $keyColor;
  }
}


///////////////////////////////////////////////////////////////////////////////////////////////////////

//  text-align

///////////////////////////////////////////////////////////////////////////////////////////////////////
$breakpoint-value : xs,sm,md,lg;

.u-taL {
  text-align: left !important;
}
.u-taC {
  text-align: center !important;
  //「$breakpoint-value」で設定したブレイクポイント配列でクラスを作成。
  //例：.u-taC--xs-taL
  @each $breakpoint in $breakpoint-value {
    &--#{$breakpoint} {
      &-taL {
        @include mq-max(#{$breakpoint}) {
          text-align: left !important; //leftに切り替え
        }
      }
    }
  }
}
.u-taR {
  text-align: right !important;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  only系

///////////////////////////////////////////////////////////////////////////////////////////////////////
.u-only-xs {
  display: none !important;
  @include mq-max(xs) {
    display: block !important;
  }
}
.u-only-sm {
  display: none !important;
  @include mq-max(sm) {
    display: block !important;
  }
  @include mq-max(xs) {
    display: none !important;
  }
}
.u-only-md {
  display: none !important;
  @include mq-max(md) {
    display: block !important;
  }
  @include mq-max(sm) {
    display: none !important;
  }
}
.u-only-lg {
  @include mq-max(md) {
    display: none !important;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ページ内アンカー位置調整

///////////////////////////////////////////////////////////////////////////////////////////////////////
// .anchorReposition {
//   padding-top: $headerHeight + 10px;
//   margin-top: -1 * $headerHeight - 10px;
//   @include mq-max(md) {
//     padding-top: $headerHeight-sp + 10px;
//     margin-top: -1 * $headerHeight-sp - 10px;
//   }
// }

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  layout

///////////////////////////////////////////////////////////////////////////////////////////////////////

//互い違い
.l-alternate {
  &__wrap {
    margin-bottom: 90px;
    @include mq-max(xs) {
      margin-bottom: 60px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__img {
    @include mq-max(xs) {
      margin-bottom: -15px;
    }
  }
  &__desc {}
}
