//サイドGナビ
.l-navigation {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 110;
  @include mq-max($changepPoint) {
    width: calc(100% - 30px);
    height: 100vh;
    right: 0;
    transform: translateX(100%);
    transition: all $transition;
    -webkit-transition: -webkit-transform 0.2s;
    // visibility: hidden;
    pointer-events: none;
    overflow: hidden;
  }
  &__inner {
    background-color: $keyColor;
    padding: 0 10px;
    display: flex;
    align-items: center;
    @include mq-max(content) {
      padding: 0;
    }
    @include mq-max($changepPoint) {
      flex-direction: column;
      align-items: flex-start;
      padding: 97px 15px 50px 15px;
      width: 100%;
      height: 100%;
      overflow: scroll;
      @media (orientation: landscape) {
        padding-bottom: 100px;
      }
    }
  }
  &__item {
    @include mq-max($changepPoint) {
      width: calc(100% - 77px);
      margin-bottom: 50px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      color: $white;
      transition: all $transition !important;
      display: block;
    }
    &>a {
      font-size: 15px;
      font-weight: 600;
      padding: 41.5px 11px;
      @include mq-max(content) {
        padding: 41.5px 8px;
        font-size: 14px;
      }
      @include mq-max($changepPoint) {
        padding: 10px 15px;
        font-size: 15px;
      }
      &:hover {
        background-color: $keyColor-hover;
        color: $white;
        @include mq-max($changepPoint) {
          background-color: $keyColor;
        }
      }
      //現在地
      &.current {
        position: relative;
        &:after {
          content: '';
          display: inline-block;
          width: 2px;
          height: 20px;
          background-color: $keyColor-hover;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          @include mq-max($changepPoint) {
            width: 20px;
            height: 2px;
            bottom: auto;
            top: 50%;
            left: -15px;
            transform: translateY(-50%);
          }
        }
      }
    }

    //ドロップダウン
    &.has-dropdown {
      position: relative;
    }
  }
  //アイコン
  &__ico {
    &>a {
      display: flex;
      align-items: center;
      &:before {
        content: '';
        display: inline-block;
        margin-right: 7px;
      }
    }
    //トップ
    &--home {
      &>a {
        &:before {
          width: 30px;
          height: 25px;
          background: url('/inc/image/common/ico/ico_home-white.svg')no-repeat center / contain;
          @include mq-max(content) {
            width: 30px * .8;
            height: 25px * .8;
          }
          @include mq-max($changepPoint) {
            width: 30px;
            height: 25px;
          }
        }
      }
    }
    //料金プラン
    &--plan {
      &>a {
        &:before {
          width: 21px;
          height: 21px;
          background: url('/inc/image/common/ico/ico_wallet-white.svg')no-repeat center / contain;
          @include mq-max(content) {
            width: 21px * .8;
            height: 21px * .8;
          }
          @include mq-max($changepPoint) {
            width: 21px;
            height: 21px;
          }
        }
      }
    }
    //中国自動車学校について
    &--about {
      &>a {
        &:before {
          width: 22.5px;
          height: 22.5px;
          background: url('/inc/image/common/ico/ico_school-white.svg')no-repeat center / contain;
          @include mq-max(content) {
            width: 22.5px * .8;
            height: 22.5px * .8;
          }
          @include mq-max($changepPoint) {
            width: 22.5px;
            height: 22.5px;
          }
        }
      }
    }
    //入校のご案内
    &--guide {
      &>a {
        &:before {
          width: 29px;
          height: 20px;
          background: url('/inc/image/common/ico/ico_guide-white.svg')no-repeat center / contain;
          @include mq-max(content) {
            width: 29px * .8;
            height: 20px * .8;
          }
          @include mq-max($changepPoint) {
            width: 29px;
            height: 20px;
          }
        }
      }
    }
    //教習生の方
    &--student {
      &>a {
        &:before {
          width: 20px;
          height: 20px;
          background: url('/inc/image/common/ico/ico_pen-white.svg')no-repeat center / contain;
          @include mq-max(content) {
            width: 20px * .8;
            height: 20px * .8;
          }
          @include mq-max($changepPoint) {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    //アクセス
    &--access {
      &>a {
        &:before {
          width: 30px;
          height: 15px;
          background: url('/inc/image/common/ico/ico_bus-white.svg')no-repeat center / contain;
          @include mq-max(content) {
            width: 30px * .8;
            height: 15px * .8;
          }
          @include mq-max($changepPoint) {
            width: 30px;
            height: 15px;
          }
        }
      }
    }
  }


  //展開時
  &.is-navOpen {
    @include mq-max($changepPoint) {
      transform: translateX(0%);
      // visibility: visible;
      pointer-events: auto;
    }
  }
}

//ナビアニメーション



//ドロップダウン
.l-dropDown {
  position: absolute;
  left: 0;
  transform: translateY(-30px);
  visibility: hidden;
  opacity: 0;
  transition: all .5s ease;
  pointer-events: none;
  width: 150px;//要調節
  @include mq-max($changepPoint) {
    display: none;
    transition: opacity .5s ease;
    transform: translateY(0);
    width: 100%;
    position: relative;
    padding-left: 15px;
  }
  &>li {
    @include mq-max($changepPoint) {
      overflow: hidden;
      // height: 0px;
      transition: all .5s ease;
    }
    &>a {
      background-color: $keyColor;
      display: block;
      font-size: 15px;
      font-weight: 600;
      padding: 10px 11px;
      @include mq-max($changepPoint) {
        padding: 5px 15px;
      }
      &:hover {
        background-color: $keyColor-hover;
        color: $white;
        @include mq-max($changepPoint) {
          background-color: $keyColor;
        }
      }
    }
  }

  //展開時
  &.isDropdownOpen {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
    pointer-events: auto;

    &>li {
      @include mq-max($changepPoint) {
        // height: 32px;
      }
    }
  }
}
