//電話番号(タグマネでイベントを取るためのクラスなので、必要以上のCSSは書かないこと)
.c-tel {
  color: inherit !important;
  pointer-events: none;
  display: inline-block;

  //JSで有無を判断
  &.is-telActive {
    pointer-events: initial;
  }
}