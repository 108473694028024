.l-header {
  background-color: $white;
  height: $headerHeight;
  display: flex;
  align-items: center;
  @include mq-max($changepPoint) {
    height: $headerHeight-sp;
  }
  @if $is-fixed == true {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  &__inner {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 30px;
    position: relative;
    z-index: 100;
    // @include mq-max(md) {
    //   display: block;
    //   padding: 0;
    //   position: fixed;
    //   top: 0;
    //   background: transparent;
    // }
  }

  h1,.logo {
    margin-bottom: 0;
    margin-right: 30px;
    line-height: 1;
    display: inline-block;
    width: 196px; // 状況によって変更可
    @include mq-max($changepPoint) {
      width: 128px;
      margin: 0;
      line-height: 0;
    }
    img {
      width: 100%;
    }
  }
  .logo {
    img {
      opacity: 1;
      transition: opacity $transition;
    }
    &:hover {
      img {
        opacity: .7;
      }
    }
  }

  &__modules {
    position: relative;
    display: inline-block;
    // @include mq-max(md) {
    //   padding: 10px;
    //   background-color: $white;
    //   line-height: 0;
    // }
  }
}

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  //  ハンバーガーメニュー

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
.c-toggleBtn {
  @include mq-max($changepPoint) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background-color: $keyColor;
    width: 77px;
    height: 77px;
    cursor: pointer;
    z-index: 111;
    &__inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 24px;
      height: 18px;
    }
    span{
      display: block;
      position: absolute;
      width: 24px;
      border-bottom: solid 2px $white;
      transition: .35s ease-in-out; //変化の速度を指定
      left: 0;
      &:nth-child(1){
        top: 0px;
      }
      &:nth-child(2){
        top: 8px;
      }
      &:nth-child(3){
        top: 16px;
      }
    }
    &.is-toggleActive {
      span{
        &:nth-child(1){
          top: 6px;
          left: 0;
          transform: rotate(-45deg);
        }
        &:nth-child(2),&:nth-child(3){
          top: 6px;
          transform: rotate(45deg);
        }
      }
    }
  }
}
