/*
モジュール系おまとめファイル
*/

// ヘッダー
@import "header";

// フッター
@import "footer";

// グローバルナビゲーション
@import "navigation";

 // ページトップに戻る
@import "pageTop";

 // ボックス
@import "box";

 // ボタン
@import "button";

//テーブル
@import "table";

//リスト
@import "list";

//見出し
@import "heading";

//アコーディオン
@import "accordion";

//タブ
@import "tab";

//テキストの装飾
@import "txt";

//沿革
@import "timeline";

// ラベル
@import "label";

//お知らせ
@import "newslist";

// クラス付与型ホバーアクション
@import "hover";

//電話番号
@import "tel";
